import { PhotoManagement } from "../../containers/PhotoManagement";



const English= {
      translation: {
        header:{
          roleManagement: 'Role Management',
          testingPersonel: 'Testing Personnel',
          accountManagement : 'Account management',
          accountDetails :'Account details',
          signout :'Sign out',
          loginRegister:"Login/Register",
          Login:"Login"

        },
        sidebar:{
        dashboardLink : 'Dashboard',
        contactUsLink : 'Contact us',
        websiteLink :'Website',
        newsLink:"CCO News",
        resourcesLink:"Resources",
        storeLink:"CCO Store",

        },
        dashboard:{

          profileCard:{
          pendingApproval:"PENDING APPROVAL",
          updatePhoto:"Update Photo",
          ccoId : ' CCO ID',
          address:"Address",
          phone : 'Phone',
          email : 'Email',
          },
          myCertificationCard:{
            MyCertifications: "My Certifications",
            Status:"Status",
            ExpDate:"Exp Date",
            noCertifications:'No Certifications available',
            Statuses: {
            Active: "Active",
            Suspended: "Suspended",
            Revoked: "Revoked",
            Inactive: "Inactive",
            Approved: "Approved",
            Pending: "Pending",
            PendingRecert: "PendingRecert",
            Expired: "Expired"
          },
          shareModal:{
            title:"Share myCCO Certifications",
            shareButton:"Share",
            copyButton:"Copy",
            shareSubject:"{{name}} is sharing their CCO certifications",
            shareMessage:"{{name}} is sharing their CCO certifications with you. Click here to view their current certification status: {{url}}",
            copiedMessage:"Link copied to clipboard!",
          }
          },
        examHub:{
          heading:"myCCO Exam Hub",
          title:"Inside the myCCO Hub, you can:",
          item1:"Find written and practical test locations",
          item2:"See your exams that are available for scheduling",
          item3:"Schedule/Change exam appointments",
          item4:"View your Exam Results",
          ExamHubButton:"Launch myCCO Exam Hub"
        },
          welcomeUser : 'Welcome to the Dashboard' ,
        login : 'Log in',
        hello :'Hi {{name}},',
        applyNow : 'Apply Now For',
        learnLinkLabel : 'Learn about written testing options',
        writingTestButton:'Written Testing',
        practicalTestButton:'Practical Testing',
        candidateResources : 'Candidate Resources',
        demoExamHeading:"Test drive CCO's demo exam",
        demoExamDescription:"So that you are prepared for test day, familiarize yourself with CCO's exam delivery system. You can get comfortable with the exam layout, try out the on-screen calculator, navigate on-screen load charts, and more.",
        demoExamButton : 'Launch Demo Exam',
        heading :'to OPT or EOT testing? Take a test drive!',
        paragraph : ' Familiarize yourself with CCO’s new exam delivery system. CCO has created a free demo exam allowing you to navigate then exam delivery system and try out the on-screen calculator, working with load chart PDF files, reviewing/flagging questions, and more.',
        certificate : 'My Certifications',
        para2 : 'No certificates found',
        schedule : 'Upcoming Exams / Pending Results',
        para3 : 'No upcoming exams found',
        para4 : '*Examination results are subject to CCO determinations of validity and other appropriate corrections.',
        para5 : 'No history exams found',
        head2 : '  Exam History *' ,
        h2detail : 'previous 12 months',
        button1: 'Written Testing - EOT/OPT'
      },
      account:{
        incompleteProfile:"Complete your profile now to access your CCO ID and submit an exam application.",
        pageHeading:"Account Details",
        personalInfo:"1. Personal Information",
        employeeInfo:"2. Employer Information",
        personalForm:{
          fullName:"Full Name",
          dob:"Date of Birth",
          email:"Email",
          mobilePhone:"Mobile Phone",
          street1:"Street 1",
          street2:"Street 2",
          country:"Country",
          noCountry:"No countries found. Please try again later.",
          city:"City",
          state:'State',
          selectCountry:'Select a country first',
          noState:'No states found. Please try again later.',
          selectState:'Select a state',
          selectaCountry:'Select a country',
          zip:"Zip",
          

        },
        
      
        employeeForm:{
          organization:"Organization",
          phone:"Phone",
          address1:"Address 1",
          address2:"Address 2",
          country:"Country",
          noCountry:"No countries found. Please try again later.",
          city:"City",
          zip:"Zip",
        },
        successMessage:"Successfully updated your account, click Return to Dashboard button to continue.",
        saving:"Saving...",
        saveChangesButton:"Save Changes",
        returnDashboardButton:"Return to Dashboard"
      },
      testingPersonnel:{
        pageHeading:"Testing Personnel",
        ccoId:"CCO ID",
        noCredential:"No Credentials available",
        credentialJourneyButton:"Start Credential Journey",
        availableCredentialButton:"View all Available Credentials",
      },
      myCredential:{
        breadcrumbsLink:"Testing Personnel",
        status:"Status",
        expiryLabel:"Training Due",
        noExpiry:"Not Available",
        pendingAuditMessage:"Contact CCO to schedule your audit.",
        pendingCertification:"You must complete your certification in order to earn your credential.",
        renewButton:"Renew Now",
        workshopTitle:"Workshops",
        startEnd:"Start Date/Time",
        name:"Name",
        launchLink:"LaunchLink",
        location:"Location",
        noWorkshop:"No workshop applications available",
        description:"Description",
        manageMyTestAdministrations:"Manage My Test Administrations",
        Resources:"Resources",
        paperPencilTesting:"Written Testing - PPT",
         Statuses: {
              Pending: "Pending",
              PendingAudit: "Pending Audit",
              Inactive: "Inactive",
              PendingCertification: "Pending Certification",
              Approved: "Approved",
              Expired: "Expired",
              HOLD: "Hold",
              Retired: "Retired",
              PendingRenewal: "Pending Renewal"
            },
            workshopStatuses: {
              Active: "Active",
              Inactive: "Inactive",
              Pending: "Pending",
              Approved: "Approved",
              Attended: "Attended",
              Completed: "Completed"
            }
      },
      Credential:{
        testingPersonel:'Testing Personnel',
        JourneyStatus:'  Journey Status',
        PreReq:{
        WrittenExamText1 :'Written Exam Required',
        WrittenExamText2 :'Click here to sign up',
        ExamStatus : 'Status',
        ExamTestDate : 'Test Date',
        CertificationText1 :'Certification Required',
        CertificationText2 :'Click here to sign up',
        CertExpirationDate :'Expiration Date',
        PreReqStatus:'Status:',
        PreReqExpirationDate : 'Expiration Date: ',
        NotPurchased : ' Not Purchased',
        PreReqPara : 'There are no pre-requisites for',
         PreReqPara2: 'credential.',
        },
        BillingInfo:{
         billinginfo : 'Billing Information',
         billingNote :'Note: We Accept VISA, MasterCard, and American Express.',
         CardNumber : 'Credit Card Number',
         TypeCreditCard : 'Credit Card',
         Error:'Something went wrong',
         CloseWorkshopPara :'No closed workshop available against this',
         CloseWorkshopPara2 :'access code.',
         Process :'Processing',
         Pay :'Pay $',
         AccessCodefield:'Enter access code',
         AccessCode:'Access Code',
         Loading:'Loading',
         billingbuttonText :'Click here to fill Credit Card Information',
         formbuttonText : 'Continue',
         formHeaderText :'Payment',
         Cardinfo: ' Please fill credit card information!',
         FirstNamefield :' First Name',
         LastNamefield :' Last Name',
         Emailfield : ' Email',
         Phonefield : 'Phone',
         Countryfield : 'Country',
         Addressfield: 'Address',
         Cityfield:'City',
         Statefield: 'State',
         Zipfield :'Zip',
         SuccessResponse :'Request submited successfully.',
        },
         
         CredentialInfo :'Credential Information',
         CredentialEmpty : 'Credential information is empty',
         Workshop:{
         AvailableWorkshop: 'Available Workshops',
         WorkshopError: 'You must select one workshop to proceed.',
         Startdate:'Start Date/Time*',
         Enddate: 'End Date/Time*',
         Location:'Location',
         AvailableSeats :'Available Seats',
         ApplicationDeadline :'Application Deadline',
         AuditEvent : 'Audit Event',
         WorkshopPara1: ' Sorry, there are currently no available workshops. You are not able to continue until a workshop becomes available. If you`d like to be added to the waitlist, please email',
         WorkshopPara2:'Provide your name, CCO ID, and indicate which workshop you are interested in attending. We will notify you when additional workshops are added.',
         },
         Agreement :'Agreement',
         termsandCondition : '  I understand and agree to this Agreement. I further understand and agree that a payment to register or enroll in any testing personnel training (such as a course or a workshop) cannot be refunded once my request and payment are submitted to CCO. In addition, I understand that  any such registration cannot be cancelled or transferred. By submitting a payment, I agree to CCO `s',
         FinancialTerms :' Financial Terms and Conditions',
         ProceedtCheckout :' Proceed to checkout',
         Submit:'Submit',
         Title:'Please wait while payment is processed. Do not refresh page or click back button.'
        },
        CredentialRenewal:{
          testingPersonel:'Testing Personnel',
          JourneyStatus:'  Journey Status',
          WrittenExamText1 :'Written Exam Required',
          WrittenExamText2 :'Click here to sign up',
          ExamStatus : 'Status',
          ExamTestDate : 'Test Date',
          CertificationText1 :'Certification Required',
          CertificationText2 :'Click here to sign up',
          CertExpirationDate :'Expiration Date',
          PreReqStatus:'Status:',
          PreReqExpirationDate : 'Expiration Date: ',
          NotPurchased : ' Not Purchased',
          PreReqPara : 'There are no pre-requisites for',
           PreReqPara2: 'credential.',
           billinginfo : 'Billing Information',
           billingNote :'Note: We Accept VISA, MasterCard, and American Express.',
           CardNumber : 'Credit Card Number',
           TypeCreditCard : 'Credit Card',
           Error:'Something went wrong',
           CloseWorkshopPara :'No closed workshop available against this',
           CloseWorkshopPara2 :'access code.',
           Process :'Processing',
           Pay :'Pay $',
           AccessCodefield:'Enter access code',
           AccessCode:'Access Code',
           Loading:'Loading',
           billingbuttonText :'Click here to fill Credit Card Information',
           formbuttonText : 'Continue',
           formHeaderText :'Payment',
           Cardinfo: ' Please fill credit card information!',
           FirstNamefield :' First Name',
           LastNamefield :' Last Name',
           Emailfield : ' Email',
           Phonefield : 'Phone',
           Countryfield : 'Country',
           Addressfield: 'Address',
           Cityfield:'City',
           Statefield: 'State',
           Zipfield :'Zip',
           SuccessResponse :'Request submited successfully.',
           CredentialInfo :'Credential Information',
           CredentialEmpty : 'Credential information is empty',
           AvailableWorkshop: 'Available Workshops',
           WorkshopError: 'You must select one workshop to proceed.',
           Startdate:'Start Date/Time*',
           Enddate: 'End Date/Time*',
           Location:'Location',
           AvailableSeats :'Available Seats',
           ApplicationDeadline :'Application Deadline',
           AuditEvent : 'Audit Event',
           WorkshopPara1: ' Sorry, there are currently no available workshops. You are not able to continue until a workshop becomes available. If you`d like to be added to the waitlist, please email',
           WorkshopPara2:'Provide your name, CCO ID, and indicate which workshop you are interested in attending. We will notify you when additional workshops are added.',
           Agreement :'Agreement',
           termsandCondition : '  I understand and agree to this Agreement. I further understand and agree that a payment to register or enroll in any testing personnel training (such as a course or a workshop) cannot be refunded once my request and payment are submitted to CCO. In addition, I understand that  any such registration cannot be cancelled or transferred. By submitting a payment, I agree to CCO `s',
           FinancialTerms :' Financial Terms and Conditions',
           ProceedtCheckout :' Proceed to checkout',
           Submit:'Submit',
           Title:'Please wait while payment is processed. Do not refresh page or click back button.',
           Renew :'Renew',
          AuthorizedError:'Our records indicate that you are not authorized to access this page. Please contact CCO if you have any questions. ',
          NotRenewed :' Not Renewed',
         NotAvailable : 'Not Available',
         ProductError:'Credential product fee not found!'
        }
      ,credentialjourney:{
        CredentialJourney: "Credential Journey",
        testingPersonnel:"Testing Personnel",
        SelectJourneyButton :"Select your Credential journey",
      },
      CAD:{
        CadManagement:{
        pageHeading:"Testing Personnel",
        ccoId:"CCO ID",
        PracLocations:'Practical Locations',
        CustomCaD:'My Custom CADs',
        BacktoPrac:'Back to Prac Test Site',
        AddnewCad:'Add new Custom CADs',
        PendingCad:'My Pending Review Custom CADs',
        ApproveCad:'My Approved Custom CADs',
        CompleteCad:'My Completed Custom CADs',
        ExpireCad:'My Expired Custom CADs',
        NoCustomCad:'No Custom CADs found',
        },
        BillingInformation:{
        billinginfo : 'Billing Information',
        billingNote :'Note: We Accept VISA, MasterCard, and American Express.',
        CardNumber : 'Credit Card Number',
        TypeCreditCard : 'Credit Card',
        Error:'Something went wrong',
        Process :'Processing',
        Pay :'Pay $',
        billingbuttonText :'Click here to fill Credit Card Information',
        formbuttonText : 'Continue',
        formHeaderText :'CAD Payment',
        Cardinfo: 'It`s compulsory to fill credit card information first',
        FirstNamefield :' First Name',
        LastNamefield :' Last Name',
        Emailfield : ' Email',
        Phonefield : 'Phone',
        Countryfield : 'Country',
        Addressfield: 'Address',
        Cityfield:'City',
        Statefield: 'State',
        Zipfield :'Zip'
      },
       CADlanding:{
        OptionYes:'Yes',
        OptionNo :'No',
        descrip1: 'Is your test weight anything other than 3 ft. outside diameter?',
        for1:"For All Mobile Cranes:",
        descrip2: "Does your crane have rear outrigger spread of greater than 24 ft.?",
        for2:"For Boom Trucks:",
        descrip3: "Does your crane have less than 42 ft. of main boom (jibs not permitted)?",
        descrip4: "Does your crane operate only with a remote control?",
        descrip5: "Does your crane have less than 30 ft. of main boom?",
        for5:"For Carry Deck Cranes:",
        descrip6: "Does your crane have less than 50 ft. of main boom?",
        for6:"For Telescopic Boom Cranes (Carry Deck exempt):" ,
    },
        CustomCAD:{
        pageHeading:"Testing Personnel",
        OptionYes:'Yes',
        OptionNo :'No',
        TscPrac:'TSC-Practical',
        NewCustom:'New Custom CAD',
        CustomCAD:'Custom CAD',
        CADDescription :'CAD Description',
        CADQuestion:'Use the questions below to determine if a custom CAD is required.',
        CraneType:'To proceed with Custom CAD, select the Crane type:',
        Next:'Next',
        CADinfo:'Information',
        Makefield:'Make',
        Modelfield:'Model',
        SerialNumberfield :'Serial Number',
        MaxRelatedfield :'Max Related Capacity(Tons)',
        LATfield:'What boom length can you set that is between 70-90ft or 110-130ft of main boom?',
        otherfield:'What is the maximum main boom length?',
        Truckfield:'Is this a boom truck?',
        SelectOption:' Select an option',
        OptionNull:'N/A',
        Workingareafield:'Working area (degrees)',
        degree:'degree',
        Cranefield:'Crane Position',
        behindOption:'Behind cab',
        CenterOption:'Center mount',
        RearOption:'Rear mount',
        FifthOption :'Fifth wheel',
        Truckfield :'Truck Bed Length (ft)',
        FrontOutriggerfield :'Front Outrigger/Stablizer Spread(ft)',
        BackOutriggerfield:'Back Outrigger/Stablizer Spread(ft)',
        RopeSizefield:'Rope Size & Type',
        Allowablefield:'Allowable line pull as stated in the load chart (lb)',
        Diameterfield:'Diameter of test weight (ft) ' ,
        UploadLoadChart :'Upload Load Chart Documentation',
        ClicktoUpload:'Click to Upload',
        DownloadChart:'Download Load Chart',
        UploadLinePullChart:'Upload Line Pull Chart',
        DownloadLinePull:'Download Line Pull',
        UploadRangeDiagram:' Upload Range Diagram',
        DownloadRange:' Download Range Diagram',
        DeliveryType:'Select which type of delivery method you would like to purchase:',
        StandardType:'- Standard Custom CAD delivery is 5-7 Business Days.',
        RushType:' - Rush Delivery 2-3 Business Days.',
        SuccessError:"Successfully added Custom CAD.",
        Title:'Please wait while payment is processed. Do not refresh page or click back button.'
        }
      },

      AvaliableCredential:{
        pageHeading:"Testing Personnel",
        AvailableCredentials:'Available Credentials',
        NoCredentials :'No Credentials available',

      },
      NewLocation:{
        Attestation:{
    entry: 'Only Testing Room, with a single point of entry/exit during testing',
    proctor: 'One Proctor Workstation, located in the testing room',
    stations: 'One candidate testing station',
    parking: 'Adequate Parking for the number of vehicles anticipated',
    restroom: 'Restroom is available on-site',
    equipment: 'Candidate/equipment spacing in accordance with CCO policy',
    Attestation:'Attestation',
    Completed:"Completed",
    InProgress:"In Progress",
    FillFields:'Please fill out all fields',
    View:'View',
    NothingSelected:'Nothing selected',
    Edit:'Edit',
    station:'Testing stations and equipment in accordance with CCO policy',
    proctors:'Dedicated proctor station located in testing room',
    entrys:'A dedicated, secure testing room, with controlled entry/exit during testing',
    Req:'Do you have the following requirements?'
        },
        newlocations:{
          Nextstep:"Next step",
          Goback:"Go back",
          Previousstep:"Previous step",
          SubmitApplication:"Submit Application",
          note:"I understand and agree that an application to register a test site cannot be cancelled or transferred, and payments for application fees cannot be refunded, once the application and payment are submitted to CCO. I also declare that the information contained in this application, as well as the information in any required accompanying documentation, is true and correct. I understand that insufficient or inadequate information may result in delays in processing. By submitting a payment, I agree to CCO's",
          FinancialTermsandConditions:"Financial Terms and Conditions",
          AddEOTTestSite:"Add EOT Test Site",


          EOT:"EOT Test Site",
          Adding:"Successfully added the new location",
          saei:'Save & Exit',
          sa:'Save changes'



        },
        Summary:{
          Summary:"Summary"

        },
        BillingInfo:{
          Payment:'Payment',
          FillFields:'Please fill out all fields',
          View:'View',
          CardNumber :'Card Number',
          Completed:"Completed",
          Amount:'Amount',
          FirstName :'First Name',
          LastName:'Last Name',
          Email:'Email',
          Phone:'Phone',
          Address:'Address',
          Country:'Country',
          City:'City',
          Zip:'Zip',
          State:'State',
          Edit:'Edit',
          Cardinfo: 'It`s compulsory to fill credit card information first',
          formHeaderText :'Location Payment',
          billingbuttonText :'Click here to fill Credit Card Information',
          formbuttonText : 'Continue',
          billinginfo : 'Billing Information',
          billingNote :'Note: We Accept VISA, MasterCard, and American Express.',
          CardNumber : 'Credit Card Number',
          TypeCreditCard : 'Credit Card',
          Instruction:'Instructions: Complete the following page using the credit card holder’s information and billing address.'
        },
      
        TestingFacilityincludingSignage:{
          Photo:"2. Photo of Testing Facility including Signage",
          upload:"Upload Testing Facility Photo",
          picdetail:"5MB max – only .jpeg/.jpg, .png, or .pdf file types supported",
          upload:"Click Here to Upload",
          sampletesting:"Sample Testing Facility Photo",

        },
        TestingSite:{
          TestSiteCapacity :'Test Site Capacity',
          FillFields:'Please fill out all fields',
          InProgress:"In Progress",
          View:'View',
          
          Edit:'Edit',
          NoAnswer:'Not answered',
          Requir:'Do you have the minimum download/upload speed required?',
          Testingstations :'Testing stations',
          Completed:"Completed",
          NotCompleted:'Not completed',
          hA:'EOT Test Site Internet Requirements (Minimum)',
          para1A:' Testing Facilities must be able to meet the following technical requirements to be approved for EOT testing:',
          list1:'Non-Cellular Internet Source (e.g., broadband, cable)',
          list2:'Recommended download speed of 12 Mbps per device (minimum 2 Mbps)',
          list3:'Recommended upload speed of 3 Mbps per device (minimum 2 Mbps)',
          Note1A:'Note: Cellular/mobile hot spots or cellular MiFi devices are not supported.',
          list4:'Dedicated internet network',
          list5:'Recommended: hard-wired internet network (Ethernet)',
          list6:'Minimum: Wireless Internet Network (WiFi)',
          list7:'Private and Password Protected',
          list8:'Firewalls Disabled',
          Note2A:'Note: Public or shared networks are not supported (e.g., hotel public WiFi)..',
          para2A:'Review the full technical requirements as found in the Test Administration Handbook – EOT Testing, and access online tools to measure the internet speed – ',
          click:'click here',
          para3A:' Don’t know your internet speed? Contact testing facility IT support or run a speed test. This link will allow you to run a live speed test:',
          link:"https://www.speedtest.net/",
          performthetest:"Make sure you perform the test in the testing room you plan to use",
          capacity:"B.	Test Site Capacity",
          factors:"Based on all factors, including the available internet speed, internet network and any spacing or equipment limitations, how many candidate testing stations is the facility able to support?",
          attestation3:"C.	Attestation Statement",
          conformation:"I have confirmed that this testing facility meets the EOT Test Site Internet Requirements, and that the internet download/upload speeds will support the number of candidate testing stations entered above.",
          option:"Select an option",
          Yes:"Yes",
          No:"No - Yes is required to submit application",

        },
        TestingRoomLayout:{
          roomlayout:"4. Testing Room Layout",
          upload:"Upload Testing Room Layout",
          picdetail:"10MB max – only .jpeg/.jpg, .png, or .pdf file types supported",
          uploadhere:"Click Here to Upload",
          download:"Download Testing Room Layout Form"
        },

        Representation: {
          representativeInformation: "Test Site Representative Information",
          testingFacility:"The Test Site Representative is the Testing Facility Company Representative. This person authorizes the Test Site Coordinator to set up this test site.",
          name: "Full Name",
          phone: "Mobile Phone",
          email: "Email",
          emailconfirmation: "Email Confirmation",
          uploadtsragreement: "Upload TSR Agreement",
          upload:"Click to Upload",
          maxsizemb: "10MB max (pdf)",
          terms_conditions:"The terms & conditions field is required",
          downloadagreement: "Download Test Site Representative Agreement",
        },
        TestSite:{
          testSite:"1. Test Site Information",
          FacilityTest:"Testing Facility Company Name",
          requireName:"The company name field is required",
          Street1 : "Street 1",
          steet1Require:"The street1 field is required",
          street2: "Street 2",
          eg: "(e.g. Suite #, Building #, Room #)",
          country:"Country",
          nocountry:"No countries found. Please try again later.",
          countryRequire: "The country field is required",
          city: "City",
          cityField: "The city field is required",
          state:"State",
          nostates:"No states found. Please try again later.",
          staterequied: "The state field is required",
          zip: "Zip",
          zipRequired: "The zip field is required",
          securefacility: "Is this a secure facility, that has additional access requirements?",
          securerequire:"The secure facility field is required",
          option: "Select an option",
          YES: "Yes",
          NO: "No",
          Timezone: "Timezone",
          timezonerequired: "The timezone field is required",
          notimezone: "No timezone found. Please try again later.",
          Additional: "Please provide additional security instructions that would be required for CCO personnel to access the facility",
          africa:"E. Africa Standard Time",
          hawain: "Hawaiian Standard Time",
          alaskan: "Alaskan Standard Time",
          pacific:"Pacific Standard Time",
          pacificmexico: "Pacific Standard Time (Mexico)",
          us: "US Mountain Standard Time (AZ)",
          mountain: "Mountain Standard Time",
          central: "Central Standard Time",
          eastren: "Eastern Standard Time",
          atlantic: "Atlantic Standard Time",
          west: "West Pacific Standard Time",
          usAz:"US Mountain Standard Time (AZ)",


          selecttz:"Select a timezone",
          selectstate:"Select a state",
          selectcountry:"Select a country",
            
        },
        STestSiteRepresentativeInformation:{
          testsite:"Test Site Representative Information",
          InProgress:"In Progress",
          FullName:"Full Name",
          fill:"Please fill out all fields",
          View:"View",
          Phone:"Phone",
          Email:"Email",
          Completed:"Completed",
          TSRAgreement:"TSR Agreement",
          Edit:"Edit",
          Notcompleted:"Not completed",
          
        },
        LocationDoorSign:{
          LocationDoorSign:"Location Door Sign",
          InProgress:"In Progress",
          fill:"Please fill out all fields",
          View:"View",
          Edit:"Edit",
          Completed:"Completed"
        },
        LocationSitePlan:{
          LocationSitePlan:"Location Site Plan",
          fill:"Please fill out all fields",
          InProgress:"In Progress",
          View:"View",
          Completed:"Completed",
          Edit:"Edit"
        },
        LocationInformation:{
          LocationInformation: "Location Information",
          InProgress:"In Progress",
          fill:"Please fill out all fields",
          View:"View",
          CompanyName:"Company Name",
          Street1:"Street 1",
          Street2:"Street 2",
          City:"City",
          Country:"Country",
          Additionalsecurity:"Additional security",
          Zip:"Zip",
          State:"State",
          Country:"Country",
          Securefacility:"Secure facility ?",
          Edit:"Edit",
          Completed:"Completed",
          Notcompleted:"Not completed",
          waiting:"Waiting..."
        }
      

      },
      GeneratePdf: {
        Sheet  :{ 
          sheet1:"Generate Score Sheet PDFs",
          sheet2: "Generate Signalperson Exam PDF",
          sheet3: "Generate Signalperson CRG PDF"
        },
        Headerpage1:{
          practicalExamScoreSheetDownload: 'Practical Exam Score Sheet Download',
          termsAndConditions: 'By downloading score sheets, you agree to the following terms and conditions:', 
          credentialProctor: 'You are a credentialed Practical Exam Proctor with at least one proctor credential designation.',
          scoreSheetRestrictions: 'You may only download score sheets for credential designations that you possess.',
          approved: 'You are approved to administer CCO practical exams.',
          scoreSheetUsage: 'Each score sheet is serialized and can be used only once. You may print them once, and no copies can be made after printing.',
          expirationDetails: 'Each score sheet has an expiration date (top right corner) and may not be used after its expiration date.',
          destructionPolicy: 'Unused (expired) score sheets must be destroyed, and PDF files must be deleted after use.',
          copyrightProtection: 'You understand that the score sheets are protected by federal copyright law.',
          sharingRestriction: 'You will not share this link or any score sheets with anyone. Only you are allowed to handle or submit practical exam candidate score sheets.',
          secureStorageRequirement: 'You will keep all score sheets in a secured location.',
          deletionRequirement: 'You will delete all downloads or destroy any hard copies of score sheets if required to do so by CCO.',
          consequenceAwareness: 'You understand the consequences as outlined in the Practical Exam Proctor Handbook on the custody, care, and control of the score sheets.',
          sanctionAvoidance: 'You are not acting to circumvent the sanction of another Practical Exam Proctor.',
          examAdministration: 'You agree to administer the exams according to CCO policies and procedures.',
          printingRecommendation: 'It is highly recommended that all score sheets be printed double-sided.',
          acknowledgment: 'I acknowledge that I have read and understood the above policies and procedures in their entirety and agree to abide by them. (This box must be checked to place an order.)',
          label: 'Score Sheet Type *',
          dropdownPlaceholder1: 'Select an option', 
          label2: 'Quantity *',
          dropdownPlaceholder: 'Select an option',
          dropdown1: "ANCHOR/MICROPILE DRILL RIG" ,
          dropdown2: "ARTICULATING BOOM CRANE",
          dropdown3: "ARTICULATING BOOM LOADER",
          dropdown4: "CONCRETE PUMP OPERATOR – CLASS 1",
          dropdown5: "CONCRETE PUMP OPERATOR – CLASS 2",
          dropdown6: "DEDICATED PILE DRIVER",
          dropdown7: "DIGGER DERRICK",
          dropdown8: "FOUNDATION DRILL RIG",
          dropdown9: "MCO-LATTICE BOOM CRANE (LAT)",
          dropdown10: "MCO-TELESCOPIC BOOM-SWING CAB (TLL)",
          dropdown11: " MCO-TELESCOPIC BOOM-FIXED CAB (TSS)",
          dropdown12: " OVERHEAD CRANE OPERATOR",
          dropdown13: "RIGGER LEVEL I",
          dropdown14: "RIGGER LEVEL II",
          dropdown15: "SERVICE TRUCK CRANE OPERATOR",
          dropdown16: "SIGNALPERSON",
          dropdown17: "TELEHANDLER OPERATOR - FIXED",
          dropdown18: "TELEHANDLER OPERATOR - ROTATING",
          dropdown19: "TOWER CRANE OPERATOR ",  
          back: "Back"

          },
          Headerpage2: {
            signalPerson: "SIGNALPERSON EXAM AND CRG DOWNLOAD",
            agreement: "By downloading the Signalperson Practical Exam and Candidate Response Guide, you agree to the following terms and conditions:", 
            credentialPracticalExam: "You are a credentialed Practical Exam Proctor in the Signalperson program.",
            approvalToAdminister: "You are approved to administer Signalperson practical exams.",
            responseGuide: "You understand that the Exam and Candidate Response Guide are protected by federal copyright law.",
            shareLink:"You will not share this link, Exam, or Candidate Response Guide with anyone.",
            CandidateResponse: "You will keep the Exam and Candidate Response Guide in a secured location.",
            copiesOfExam: "You will delete all copies of the Exam and Candidate Response Guide if required to do so by CCO.",
            consequenses: "You understand the consequences as outlined in the Practical Exam Proctor Handbook on the custody, care, and control of the Exam and Candidate Response Guide.",
            practicalExamProtor: "You are not acting to circumvent the sanction of another Practical Exam Proctor.",
            CCOPoliceandProcedure: "You agree to administer the exams according to CCO policies and procedures.",
            acknowledgment: "I acknowledge that I have read and understood the above policies and procedures in their entirety and agree to abide by them."
        }, 
        },

        EOTComponents:{
          EOTComponent:{
          TestingPersonnel:"Testing Personnel",
          TestSiteLocations:"Test Site Locations",
          CCOID:"CCO ID",
          MyEOTTestSites:"My EOT Test Sites",
          AddnewEOTtestsite:"Add new EOT test site",
          Notestsitesfound:"No test sites found",
          MyPendingPaymentEOTTestSites:"My Pending Payment EOT Test Sites",
          MyPendingReviewEOTTestSites:"My Pending Review EOT Test Sites",
          Notestsitesfound: "No test sites found",
          MyApprovedEOTTestSites:"My Approved EOT Test Sites",
          Notestsitesfound:"No test sites found",
          MyExpiredEOTTestSites:"My Expired EOT Test Sites",
          Notestsitesfound: "No test sites found",
          manageMyTestAdministrations:"Manage My Test Administrations"

          },
          Mainpage:{
            ExpireDate:"Expire Date:",
            View:"View",
            CompanyName:"Company Name",
            Address:"Address",
            Status:"Status",
            ExpireDate:"Expire Date",
            Edit:"Edit",
            RenewNow:"Renew Now"
          }
          
        },
        
        Banner:{
          CandidateAccessControl:{
            note:"IMPORTANT: We need your photo! Please upload your headshot as soon as possible to ensure there are no delays in testing or receiving your certification card.",
            red: 'Your account is locked. Should you have any questions, please contact Customer Service at candidate@nccco.org or 703-560-2391',
            orange: 'Your account is currently suspended. Some account and related data may still be visible. Should you have any questions, please contact Customer Service at candidate@nccco.org or 703-560-2391.',
            yellow: 'You are currently required to schedule your exam appointment date/time at least two business days in advance. Should you have any questions, please contact Customer Service at candidate@nccco.org or 703-560-2391.',
          
          },
          testingpersonalBanner:{
            yellow: 'Your testing personnel account is subject to the test administration advanced scheduling requirement. You may only create or be assigned to a test administration with a start date/time at least two business days into the future. Should you have any questions, please contact Testing Personnel Support at testingpersonnel@nccco.org or 703-560-2391 ext. 812.',
            orange: 'Your testing personnel account is currently suspended. Some account and related data may still be visible. Should you have any questions, please contact Testing Personnel Support at testingpersonnel@nccco.org or 703-560-2391 ext. 812.',
            red: 'Your testing personnel account is locked. Should you have any questions, please contact Testing Personnel Support at testingpersonnel@nccco.org or 703-560-2391 ext. 812',
          }
        } ,

        cards:{
          ContactCredentialCard:{
            Status: "Status",
            TrainingDue:"Training Due",
            Statuses: {
              Pending: "Pending",
              PendingAudit: "Pending Audit",
              Inactive: "Inactive",
              PendingCertification: "Pending Certification",
              Approved: "Approved",
              Expired: "Expired",
              HOLD: "Hold",
              Retired: "Retired",
              PendingRenewal: "Pending Renewal"
            }
            
          }
        },
        NewPracLocation:{
          LocationInfromation:{
            TestingFacilityCompanyName:"Testing Facility Company Name",
            Street1:"Street 1",
            TestSiteInformation:"Test Site Information",
            Street2:"Street 2",
            str2:"(e.g. Suite #, Building #, Room #)",
            Selectacountry:"Select a country",
            Country:"Country",
            noCountry:"No countries found. Please try again later.",
            City:"City",
            State:"State",
            Selectastate:"Select a state",
            noState:"No states found. Please try again later.",
            Zip:"Zip",
            zipreq:"Is this a secure facility, that has additional access requirements?",
            Selectanoption:"Select an option",
            Yes:"Yes",
            No:"No",
            Timezone:"Timezone",
            Selectatimezone:"Select a timezone",
            Notimezone:"No timezone found. Please try again later.",
            provide:"Please provide additional security instructions that would be required for CCO personnel to access the facility"


          },
          newpracloc:{
            PracticalTestSite: "Practical Test Site",
            AddPracticalTestSite: "Add Practical Test Site",
            note:"I understand and agree that an application to register a test site cannot be cancelled or transferred, and payments for application fees cannot be refunded, once the application and payment are submitted to CCO. I also declare that the information contained in this application, as well as the information in any required accompanying documentation, is true and correct. I understand that insufficient or inadequate information may result in delays in processing. By submitting a payment, I agree to CCO's.",
            note2:"Financial Terms and Conditions",
            added:"Successfully added the new location",
            back:'Go back',
            step:'Previous step',
            Submit:"Submit Application",
            next: 'Next step',
            sa:'Save changes',
            saei:'Save & Exit',
            wait:'Updating please wait...',
            plz:'Please wait while submission is processing. Do not click Back or Refresh.',

            
          },
          locationDoorSign:{
            photo:"Photo of Testing Facility including Signage",
            upphoto:"Upload Testing Facility Photo",
            mb:"5MB max",
            upload:"Click Here to Upload",
            sample:"Sample Testing Facility Photo",

            CourseArea:"Practical Testing Course Area Photo",
            uploadphoto:"Upload Photo of Practical Test Area",
            mb:"10MB max",
            upload:"Click Here to Upload",
          },
          Examofferd:{
            PracticalExams :"Practical Exams Offered",
            select:"Please select the CCO practical exams you plan to offer at this test site.",
            note: "I understand and acknowledge that CCO has established unique and specific criteria for each practical exam type and the related equipment to be used for testing, which may include but is not limited to, the testing area size, testing area surface, levelness of the ground, proximity to obstructions and hazards and that the equipment to be used for testing is compliant with all applicable industry and manufacturer standard(s). I attest that I have read and familiarized myself with CCO's criteria for Practical Test Sites, as set forth in the Test Administration Handbook - Practical and agree to prepare this Test Site and related equipment in accordance with the stated requirements. I furthermore attest that the testing course is free of all training aides and training/reference materials."
            
          },
          TestSiteRepresentativeInfo:{
            TestSiteRepresentativeInformation:"Test Site Representative Information",
            note:"The Test Site Representative is the Testing Facility Company Representative. This person authorizes the Test Site Coordinator to set up this test site.",
            FullName:"Full Name",
            MobilePhone :"Mobile Phone ",
            Email:"Email",
            EmailConfirmation:"Email Confirmation",
            UploadTSR:"Upload TSR Agreement",
            mb:"10MB max (pdf)",
            btn:"Click to Upload",
            download: "Download Test Site Representative Agreement"
          },
          Attestation:{
            Attestation:"Attestation",
            req:"Do you have the following requirements?",
            ade:"Adequate parking for the number of anticipated vehicles",
            availible:"Restrooms available on-site",
            pretest:" A pre-test briefing area for candidates waiting to test (unable to observe candidates who are live testing)",

          },
          BillingInformation:{
            BillingInformation:" Billing Information",
            note:"Note: We Accept VISA, MasterCard, and American Express.",
            note2:"It's compulsory to fill credit card information first",
            FirstName:"First Name",
            LastName:"Last Name",
            Email:"Email",
            Phone :"Phone ",
            Country: "Country",
            Address:"Address",
            City:"City",
            State:"State",
            Zip: "Zip",
            btn1:"Click here to fill Credit Card Information",
            Continue:"Continue",
            LocationPayment:"Location Payment"


          }
        },
        SummaryPracSite:{
          LocationInformation:{
            LocationInformation:"Location Information",
          com:"Completed",
          inprog:"In Progress",
          note:"Please fill out all fields",
          View:"View",
          CompanyName:"Company Name",
          Street1:"Street 1",
          Street2:"Street 2",
          City:"City",
          Zip:"Zip",
          State:"State",
          Country:"Country",
          fac:"Secure facility ?",
          sec:"Additional security",
          Notcompleted:"Not completed",
          waiting:"Waiting...",
          Yes:"Yes",
          No:"No",
          Edit:"Edit"
          },
          LocationDoorSign:{
            LocationDoorSign:"Location Door Sign",
            com:"Completed",
            inprog:"In Progress",
            note:"Please fill out all fields",
            sup:"File type not supported",
            View: "View",
            Edit:" Edit",
          },
          PracticalTesting:{
            PracticalTesting:"Practical Testing Course Area Photo",
            com:"Completed",
            inprog:"In Progress",
            note:"Please fill out all fields",
            pracArea:"Photo of Practical Test Area",
            Edit:"Edit"

          },
          PracticalExamOffered:{
            PracticalExamOffered:"Practical Exam Offered",
            com:"Completed",
            inprog:"In Progress",
            View:"View",
            note:"Please fill out all fields",
            Nothingselected:"Nothing selected",
            Attes:"Is Practical Attestation",
            Edit:"Edit"

          },
          TestSite:{
            TestSite:"Test Site Representative Information",
            com:"Completed",
            inprog:"In Progress",
            View:"View",
            note:"Please fill out all fields",
            FullName:"Full Name",
            Phone:"Phone",
            Email:"Email",
            TSR:"TSR Agreement",
            Edit:"Edit",
            notcom:'Not completed'
          },
          Attestation:{
            Attestation:"Attestation",
            com:"Completed",
            inprog:"In Progress",
            View:"View",
            note:"Please fill out all fields",
            no:"Nothing selected",
            Edit:" Edit"
          },
          Payment:{
            Payment:"Payment",
            com:"Completed",
            inprog:"In Progress",
            View:"View",
            note:"Please fill out all fields",
            CardNumber:"Card Number",
            Amount:"Amount",
            FirstName:"First Name",
            LastName:"Last Name",
            Email:"Email",
            Phone:"Phone",
            Address: "Address",
            Country:"Country",
            City:"City",
            State :"State",
            Edit: "Edit"

          },
          Summary:{
            Summary:"Summary"
          }
          
        },

        login:{
          login:{
            login:"Welcome to the myCCO Portal!",
            reg:"Login or register for a myCCO Portal account to:",
            submit:"Submit an exam application",
            update:"Update your contact information",
            exam:"See exam results and current certifications",
            create:"Create an account in just a few easy steps. Watch a step-by-step video on how to register as an",
            candidate:"existing candidate",
            msg:"(someone who has tested with CCO in the past or is certified) or a ",
            user:"new user",

          }
        },


        PhotoManagement:{
          PhotoIDUpload:{
            PhotoManagement:"Photo Management",
            Submission:"Submit Your Government-Issued Photo ID",
            Eg:"(e.g. Driver’s License, Passport)",
            Note:"NOTE: Your Photo ID will be used to validate your identity and will be",
            permanentlydeleted:"permanently deleted",
            Verified:"once verified.",
            UpdatePhoto:"Update Photo",
            Upload:"Click to Upload",
            Webcam:"Use Webcam",
            Rotate:"Rotate",
            ZoomIn:"Zoom In",
            ZoomOut:"Zoom Out",
            Remove:"Remove",
            Reset:"Reset",
            Image:"Select Image",
            ID:"Make sure your ID upload meets CCO guidelines",
            Capture:"Capture",
            Close:"Close",
      
             
            
          },
          PhotoManagement:{
            PhotoManagement:"Photo Management",
            Dashboard:"Dashboard",
            Request:"Request has already been submitted. Please contact CCO if you have any questions.",
            Record:"Our records indicate that you are not authorized to access this page. Please contact CCO if you have any questions.",
            Submission:"Submit Your Photo and Government-Issued ID",
            Success:"Successfully updated your account, click Return to Dashboard button to continue.",
            Return:"Return to Dashboard",
            error:'Please upload only image files with the following formats: JPEG, JPG, PNG.',
            Submitting :'Submitting ...',
            Submit:'Submit Photo',
            Waiting:'Please wait while your submission is processed. Do not refresh page or click back button.',
          },
          PhotoUpload:{
            Submission:"Submit Your Passport-style Photo",
            Eg:"(color photo, solid background, no hats, no sunglasses, no filters)",
            Note:"NOTE: This photo will be used for your certification card once certification has been earned.",
            Update:"Update Photo",
            Upload:"Click to Upload",
            Webcam:"Use Webcam",
            Rotate:"Rotate",
            ZoomIn:"Zoom In",
            ZoomOut:"Zoom Out",
            Remove:"Remove",
            Image:"Select Image",
            Reset:"Reset",
            Submit:"Click here for photo submission guidelines",
            Capture:"Capture",
            Close:"Close" ,
            PhotoRec:'Face not detected. Please provide an image with a visible face.' 
         }
        },
      
          Footer:{
            Footer:"Copyright 2022-2025. All rights reserved."
          }
        
        
        // Credentials:{
        //   Mycredential:{
        //     OpenOnlineLearningCenter: "Open Online Learning Center",
        //     manageMyTestAdministrations:"manageMyTestAdministrationsUrl",
        //     Resources:"Resources",
        //     TSCEOT:"TSC-EOT",
            

        //   }
        // }


      
    },

    
    

    
    };
export default English;




  
    







  




    






  

