import React, { useEffect, useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Link, useLocation } from 'react-router-dom';
import { loginRequest } from '../../authConfig';
import { SideMenu } from './SideMenu';
import { useTranslation } from 'react-i18next';
import profile from '../../assets/images/userGrey.png'
import logo from '../../assets/images/Logo_CCO_2.png'
import us from '../../assets/images/us.svg';
import useIsMobile from '../../hooks/Helper/useIsMobile';
import NotificationBar from '../../containers/AlertBanner/AlertBanner';
import { useNotificationList } from '../../hooks/useAlertBanner';
import { RootObject } from '../../types/alertBanner';
import eng  from '../../assets/images/image 1.svg';
import span from '../../assets/images/span.svg';
import globe from '../../assets/images/icons8-globe-50.png'
import whiteglobe from '../../assets/images/icons8-globe-50 (1).png'
import close from '../../assets/images/icons8-sort-up-48.png'
import open from '../../assets/images/icons8-sort-down-48.png'
import ReactModal from 'react-modal';
import LanguageModal from './LanguageModal';
ReactModal.setAppElement('#root');
export const Header = ({ setHeaderMargin }: { setHeaderMargin: (margin: number) => void }) => {
  

    const languageOptions = [
        { value: 'English', label: 'English (US)' , flagUrl: eng},
        { value: 'Spanish', label:'Español (US)' , flagUrl: eng },
    ];
        
      
    const [isOpen, setIsOpen] = useState(false);
        const { i18n } = useTranslation();
        const [dropdownVisible, setDropdownVisible] = useState(false);
        const isMobile =useIsMobile();
        const changeLanguage = (selectedLanguage) => {
          i18n.changeLanguage(selectedLanguage);
          localStorage.setItem('i18nextLng', selectedLanguage);
          setDropdownVisible(false);
        };
        const isTablet = window.innerWidth <= 1024 && window.innerWidth > 768;
      
    const { t } = useTranslation();
    const { instance, accounts } = useMsal();
    let activeAccount;

    if (accounts.length > 0) {
        activeAccount = accounts[0]
    }

    const handleLoginRedirect = () => {
      const languageMap = {
          English: 'en',
          Spanish: 'es',
      };
      const languageCode = localStorage.getItem('i18nextLng') || 'en'; 
      const language = languageMap[languageCode]
      console.log(languageCode)
      console.log('Language Code from localStorage:', language);
      instance.loginRedirect({
          ...loginRequest,
          extraQueryParameters: { ui_locales: language },
      });
  };

    const handleLogoutRedirect = () => {
        const logoutRequest = {
            account: activeAccount,
            postLogoutRedirectUri: "/", // This should point to your home page
        };
    
        // Use the MSAL instance to perform the logout
        instance.logoutRedirect(logoutRequest)
        
            .catch((error) => console.log(error));
            
                // Remove user data from local storage after logout
                localStorage.clear();
                
                // Directly redirect to the homepage
          
    };
    
    const [isDropDownActive, setDropDownActive] = useState(false);
    const [isSidbarActive, setSideBarActive] = useState(false);
    const [isDashboard, setIsDashboard] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setIsDashboard(location.pathname === '/dashboard');
    }, [location]);

    const toggleClass = () => {
        setDropDownActive(!isDropDownActive);
    };

    const toggleSidebarClass = () => {
        setSideBarActive(!isSidbarActive);
    };

    const query = useNotificationList();
    const banners = query.data?.data?.Data || [];
    const [visibleBanners, setVisibleBanners] = useState(banners);
    const [headerMarginTop, setHeaderMarginTop] = useState(0);
    const [dismissedBanners, setDismissedBanners] = useState<Array<number | string>>([]);
    const [bannerHeights, setBannerHeights] = useState<number[]>([]);
    useEffect(() => {
      setVisibleBanners(banners.filter(b => !dismissedBanners.includes(b.Id)));
    }, [banners, dismissedBanners]);
  
    // Calculate the total height of visible banners for header margin
    useEffect(() => {
      const totalHeight = visibleBanners.reduce((sum, banner) => {
        return sum + (banner?.Height || 0); // Assuming each banner has a Height property
      }, 0);
      setHeaderMarginTop(totalHeight);
    }, [visibleBanners]);
  
    // Callback to dismiss a banner and add it to the dismissedBanners list
    const handleDismiss = (bannerId: string | number) => {
      setVisibleBanners((prevBanners) => prevBanners.filter((b) => b.Id !== bannerId));
      setDismissedBanners((prevDismissed) => [...prevDismissed, bannerId]);
    };
  
    // Callback to reopen a specific dismissed banner
    const handleReopen = (bannerId: string | number) => {
      const reopenedBanner = banners.find((b) => b.Id === bannerId);
      if (reopenedBanner) {
        setVisibleBanners((prevBanners) => [...prevBanners, reopenedBanner]);
        setDismissedBanners((prevDismissed) => prevDismissed.filter((id) => id !== bannerId));
      }
    };
  const calculateTotalHeightAbove = (index: number) => {
  return bannerHeights.slice(0, index).reduce((sum, h) => sum + h, 0);
};
const handleHeightChange = (index: number, height: number) => {
    setBannerHeights(prevHeights => {
      const newHeights = [...prevHeights];
      newHeights[index] = height;
      return newHeights;
    });
  };

  useEffect(() => {
    // Calculate the total height of visible banners
    const totalHeight = visibleBanners.reduce((sum, banner) => sum + (banner?.Height || 0), 0);
    setHeaderMarginTop(totalHeight);
    setHeaderMargin(totalHeight); // Pass the margin to parent
  }, [visibleBanners]);
    return (
      <div id="page-container">
        {/* Banners */}
        {visibleBanners.length > 0 &&
          visibleBanners.map((myCertData, index) => (
            myCertData?.Enable && (
              <NotificationBar
                key={myCertData?.Id || index}
                backgroundColor={myCertData?.BackgroundColor}
                textColor="#000"
                buttonText={myCertData?.ButtonText}
                buttonColor={myCertData?.ButtonColor}
                buttonTextColor={myCertData?.ButtonTextColor}
                buttonLink={myCertData?.ButtonAction || 'https://example.com'}
                enable={myCertData?.Enable}
                message={myCertData?.MessageText}
                allowDismiss={true}
                DisableButton ={myCertData?.DisplayButton}
                DismissColor={myCertData?.CloseButtonColor}
                onDismiss={() => handleDismiss(myCertData?.Id || index)}
                positionIndex={index} // Pass index for dynamic top calculation
                onHeightChange={(height) => {
                    myCertData.Height = height; // Set height for banner
                  }}//Track banner height
                
              />
            )
          ))}
  
        {/* Icon to reopen dismissed banners, only shown if there are dismissed banners and not all banners are visible */}
        {dismissedBanners.length > 0 && visibleBanners.length < banners.length && (
          <div style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1001 }}>
            {dismissedBanners.map((bannerId) => (
              <button
                key={bannerId}
                onClick={() => handleReopen(bannerId)} // Reopen the specific dismissed banner when clicked
                style={{
                  backgroundColor: '#dfbd26',
                  width: '20px',
                  height: '20px',
                  backgroundImage: 'url(https://img.icons8.com/metro/20/expand-arrow.png)',
                  backgroundSize: 'cover',
                  border: 'none',
                  cursor: 'pointer',
                  margin: '0 5px', // Add margin for spacing between buttons
                }}
              />
            ))}
          </div>
        )}  {activeAccount && (
            <div>
       
                <header className="cco_section cco_section_1" style={{ marginTop: headerMarginTop }} >
                    <div className="cco_row cco_row_5">
                    
                        <div className="cco_col cco_col_9">
                            
                            <div className="cco_btn cco_btn_4">
                                <button id="ccoSidebarToggle" onClick={toggleSidebarClass}>
                                    <span>Toggle Menu</span>
                                </button>
                            </div>
                        </div>

                        {isMobile ? (
      <>
        {/* Globe Icon (Left Section for Mobile) */}
        <div className="cco_col cco_col_1">
          <img
            src={globe}
            alt="Globe Icon"
            style={{ height: '25px', width: '25px', cursor: 'pointer' }}
            onClick={() => setIsOpen(true)}
          />
        </div>

        {/* Centered Logo */}
        <div className="cco_col cco_col_10">
          <div className="cco_img cco_img_0">
            <a className="cco_img_wrap" href="/dashboard">
              <img src={logo} alt="NCCCO" className="centered-logo" />
            </a>
          </div>
        </div>
      </>
    ) : (
      <>
        {/* Regular Logo Placement (for Desktop) */}
        <div className="cco_col cco_col_10">
          <div className="cco_img cco_img_0">
            <a className="cco_img_wrap" href="/dashboard">
              <img src={logo} alt="NCCCO" />
            </a>
          </div>
        </div>
      </>
    )}
                       { !isMobile &&<div className="cco_col cco_col_1">
                            {/* <div className={`cco_dropdown cco_dropdown_0 ${dropdownVisible? 'active' : null}`} onClick={() => setDropdownVisible(!dropdownVisible)} >
                                <button className={'cco_dropdown_toggle'}  >
                                <img      style={{ height : '25px', width : '30px' }}
          src={languageOptions.find(option => option.value === i18n.language)?.flagUrl}
          alt={`${i18n.language}-flag`}
        /> &nbsp;<strong><h1 style={{ alignContent :'center' , display: 'flex' }}>{i18n.language}</h1></strong>
                                    </button>
                                    <div className="cco_dropdown_content ">
                                    {dropdownVisible && (
        <ul className=" cco_menu cco_menu_1 hover-list">
          {languageOptions.map((option , index) => (
          <div  key={index} >
            <li className='cco_menu_item '
              key={option.value}
              style={{ alignContent :'center' , display: 'flex' }} 
              onClick={() => changeLanguage(option.value)}
            >
            <img
                src={option.flagUrl}
                alt={`${option.value}-flag`}
               
              style={{ marginRight: "10px", height : '28px', width : '30px' , paddingTop:'5px' }}/>&nbsp;<p style={{ alignContent :'center' , display: 'flex' }}>{option.label}</p>
            </li></div>
          ))}
        </ul>
      )}
                                    </div>
                                   </div>  */}



<div className={`cco_dropdown cco_dropdown_0 ${dropdownVisible ? 'active' : null}`} onClick={() => setDropdownVisible(!dropdownVisible)}>
    <button className={'cco_dropdown_toggle'}>
        <strong><h1 style={{ alignContent: 'center', display: 'flex',fontSize:"18px" }}><img src={globe}   style={{height : '25px', width : '25px'  }}/>
        </h1></strong>     
        {/* width : '25px'  }}/>&nbsp; { isMobile ?'': 'EN/ES'}</h1></strong>  */}
        {(isMobile || (window.innerWidth >= 768 && window.innerWidth <= 1020) )&& <img 
            src={dropdownVisible ? close : open} 
        alt="Icon" 
        style={{ width: '16px', height: '16px', marginLeft: '8px' }} 
    />}
    </button>
    <div className="cco_dropdown_content">
        {dropdownVisible && (
            <ul className="cco_menu cco_menu_1 hover-list">
                {languageOptions.map((option, index) => (
                    <div key={index}>
                        <li className='cco_menu_item'
                            style={{ alignContent: 'center', display: 'flex' }}
                            onClick={() => changeLanguage(option.value)}
                        >  <img
                        src={option.flagUrl}
                        alt={`${option.value}-flag`}
                       
                      style={{ marginRight: "10px", height : '28px', width : '30px' , paddingTop:'5px' }}/>&nbsp;
                            <p style={{ alignContent: 'center', display: 'flex' }}>{option.label}</p>
                        </li>
                    </div>
                ))}
            </ul>
        )}
    </div>
</div>
                            </div>}
          
                           

                        
                        <div className="cco_col cco_col_11">
                            <div className={`cco_dropdown cco_dropdown_0 ${isDropDownActive ? 'active' : null}`} onClick={toggleClass}>
                                <button className={'cco_dropdown_toggle'}>

                                    <img 
                                    className='cco_dropdown_toggle_img '
                                        src={profile}
                                        alt=""
                                    />
                            <div className="dropdown_content">
                             
                                </div>
 
                <span className="cco_dropdown_toggle_text">
                                        {activeAccount?.username}
                                    </span>
                                </button>
                                

<div >
       
</div>
                                <div className="cco_dropdown_content">
                               
                                     <div className="cco_text cco_text_4">
                                        <div className="cco_text_inner">{t('header.roleManagement')}</div>
                                    </div>
                                    <ul className="cco_menu cco_menu_1">
                                        <li className="cco_menu_item cco_menu_item_icon_avatar">
                                          
                                            <Link to={"/testing-personnel"} state={activeAccount} >{t('header.testingPersonel')}</Link>

                                        </li>
                                    </ul>  
                                    <div className="cco_text cco_text_4">
                                        <div className="cco_text_inner">
                                           {t('header.accountManagement')}
                                        </div>
                                    </div>
                                    <ul className="cco_menu cco_menu_1">
                                        <li className="cco_menu_item cco_menu_item_icon_gear">
                                         
                                            <Link to={"/account"} state={activeAccount} >{t('header.accountDetails')}</Link>
                                        </li>
                                        <li className="cco_menu_item cco_menu_item_icon_logout">
                                            <a href="javascript:;" onClick={handleLogoutRedirect}>{t('header.signout')}</a>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                           

                        </div>
                     
                    </div>
                    <LanguageModal
    isOpen={isOpen}
    onClose={() => setIsOpen(false)}
    languageOptions={languageOptions}
    changeLanguage={changeLanguage}
  />
                </header></div>

            )}

            {!activeAccount && (
                <div className='loginPage' >
                    <header className="cco_header" >
                        <div className="cco_header_container" style={{ marginTop: headerMarginTop }}>
                        {!isMobile && !isTablet && 
  <div className="cco_header_logo">
    <a href="/" rel='noreferrer'>
      <img
        src='sandbox1995.ccocert.org/wp-content/themes/Divi-Child/assets/images/Logo_CCO_2.png'
        alt="Logo"
      />
    </a>
  </div>
}

                            <div className="cco_header_nav">

                                <nav className="cco_header_nav_desktop" style={{ float: "right" }}>
                                    <ul className="cco_header_list_2">
                                    <li className={`cco_dropdown cco_dropdown_0 ${dropdownVisible ? 'active' : null}`} onClick={() => setDropdownVisible(!dropdownVisible)} style={{marginRight:'20px'}}>
    <button className={'cco_dropdown_toggle'}>
        <strong><h1 style={{ alignContent: 'center', display: 'flex' , color
            :'white' , fontSize:'18px'
        //  }}><img src={whiteglobe}   style={{height : '25px', width : '25px'  }}/>&nbsp; EN/ES</h1></strong>
    }}><img src={whiteglobe}   style={{height : '25px', width : '25px'  }}/></h1></strong>
         
    </button>
    <div className="cco_dropdown_content">
        {dropdownVisible && (
            <ul className="cco_menu cco_menu_1 hover-list">
                {languageOptions.map((option, index) => (
                    <div key={index}>
                        <li className='cco_menu_item'
                            style={{ alignContent: 'center', display: 'flex' }}
                            onClick={() => changeLanguage(option.value)}
                        >
                            <img
                        src={option.flagUrl}
                        alt={`${option.value}-flag`}
                       
                      style={{ marginRight: "10px", height : '28px', width : '30px' , paddingTop:'5px' }}/>&nbsp;
                            <p style={{ alignContent: 'center', display: 'flex' }}>{option.label}</p>
                        </li>
                    </div>
                ))}
            </ul>
        )}
    </div>
</li>
                                        <li className="cco_header_list_2_item cco_header_list_2_item_register">
                                            <a href='javascript:void(0)' onClick={handleLoginRedirect} rel='noreferrer'>
                                                {t('header.loginRegister')}
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="cco_header_nav_mobile"  style={{marginTop:'-45px'}}>
     
                                    <div className="cco_header_nav_mobile_closed">


                                        <ul className="cco_header_list_4">
                                        <div
  style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative', // Ensure the container behaves correctly
  }}
>
  {/* Globe Icon (Left) */}
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginLeft :'-45px',
      width: '33.33%', // Ensures equal space distribution
    }}
  >
    <button
      onClick={() => setIsOpen(true)}
      style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: 0,
      }}
    >
      <img
        src={whiteglobe}
        alt="Globe Icon"
        style={{ height: '25px', width: '25px' }}
      />
    </button>
  </div>

  {/* Logo (Center) */}
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft :'-15px',
      width: '33.33%', // Ensures equal space distribution
    }}
  >
    <img
      src="https://sandbox1995.ccocert.org/wp-content/themes/Divi-Child/assets/images/Logo_CCO_2.png"
      alt="Logo"
      className="login-logo"
      style={{ height: '58px', width: 'auto' }}
    />
  </div>

  {/* Login Button (Right) */}
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
     
      width: '33.33%', // Ensures equal space distribution
    }}
  >
  <button
  onClick={handleLoginRedirect}
  style={{
    backgroundColor: '#D38A1C',
    color: 'white',
    border: 'none',
    borderRadius: '26px',
    fontSize: '14px',
    cursor: 'pointer',
    padding: '7px 19px',
    marginRight: '-20px',
  }}
>
  {t('header.Login')}
</button>

  </div>

  {/* Language Modal */}
  <LanguageModal
    isOpen={isOpen}
    onClose={() => setIsOpen(false)}
    languageOptions={languageOptions}
    changeLanguage={changeLanguage}
  />
</div>
                


   
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>



    




                
                    </header>
                </div>
            )}
            {activeAccount && (
                <SideMenu isDashboard={isDashboard} isSidbarActive={isSidbarActive} headerMarginTop={headerMarginTop}  />
            )}





        </div>
      
    );
            

}












                    






  {/* <li className="cco_header_list_4_item cco_header_list_4_item_register">
                                                <a href='javascript:void(0)' onClick={handleLoginRedirect}>
                                                    {t('header.loginRegister')}
                                                </a>
                                            </li> */}




{/* <li className="cco_header_list_4_item cco_header_list_4_item_register">
<img
                src="https://sandbox1995.ccocert.org/wp-content/themes/Divi-Child/assets/images/Logo_CCO_2.png"
                alt="Logo"
        className="login-logo"
    />
    <a 
        href="javascript:void(0)" 
        onClick={handleLoginRedirect} 
        className="login-button"
    >
        {t('header.Login')}
    </a>
</li> */}


{/* Standby Access Section */}
                        {/* {!isMobile && (
                <div className="cco_row cco_row_4">
                    <div className="cco_col">
                        <div style={{ fontSize: "30px", color: "red", textAlign: "center" ,marginTop: '-40px' }}>
                            <strong>STANDBY MODE</strong>
                        </div>
                    </div>
                </div>
            )}
                        {isMobile && (
                <div className="cco_row cco_row_0">
                    <div className="cco_col">
                        <div style={{ fontSize: "25px", color: "red", textAlign: "center" , marginTop: '10px' }}>
                            <strong>STANDBY MODE</strong>
                        </div>
                    </div>
                </div>
            )} */}


